( function ( $ ) {
	$( function () {
		'use strict';

		/*
			Vars
		*/
		const width = $( window ).width();
		const height = $( window ).height();

		/*
			Typed
		*/
		/*$('.subtitle.subtitle-typed').each(function(){
			var subtitleContainer = $(this);

			subtitleContainer.typed({
				stringsElement: subtitleContainer.find('.typing-title'),
				backDelay: 3500, /!* Delay in text change *!/
				typeSpeed: 0, /!* Typing speed *!/
				loop: true
			});
		});*/

		/*
			Sidebar Show/Hide
		*/
		$( 'header, .profile' ).on( 'click', '.menu-btn', function () {
			$( '.s_overlay' ).fadeIn();
			$( '.content-sidebar' ).addClass( 'active' );
			$( 'body,html' ).addClass( 'sidebar-open' );
			return false;
		} );

		$( '.content-sidebar, .container' ).on(
			'click',
			'.close, .s_overlay',
			function () {
				$( '.s_overlay' ).fadeOut();
				$( '.content-sidebar' ).removeClass( 'active' );
				$( 'body,html' ).removeClass( 'sidebar-open' );
			}
		);

		/*
			Popup Menu Navigation
		*/

		$( '.main-menu li.page_item_has_children' ).each( function () {
			$( this )
				.find( '> a' )
				.after( '<span class="children_toggle"></span>' );
		} );

		$( '.main-menu' ).on( 'click', '.children_toggle', function () {
			const main_menu_item = $( this ).closest(
				'.page_item_has_children'
			);
			if ( main_menu_item.hasClass( 'open' ) ) {
				main_menu_item.removeClass( 'open' );
				main_menu_item.find( '> ul' ).slideUp( 250 );
			} else {
				main_menu_item.addClass( 'open' );
				main_menu_item.find( '> ul' ).slideDown( 250 );
			}
		} );

		/*
			Default Menu
		*/

		$( '.lnk-view-menu' ).on( 'click', function () {
			const btn_text1 = $( this ).find( '.text' ).text();
			const btn_text2 = $( this ).find( '.text' ).data( 'text-open' );
			if ( $( '.profile' ).hasClass( 'default-menu-open' ) ) {
				$( '.profile' ).removeClass( 'default-menu-open' );
				$( this ).find( '.text' ).data( 'text-open', btn_text1 );
				$( this ).find( '.text' ).text( btn_text2 );
			} else {
				$( '.profile' ).addClass( 'default-menu-open' );
				$( this ).find( '.text' ).data( 'text-open', btn_text1 );
				$( this ).find( '.text' ).text( btn_text2 );
			}

			return false;
		} );

		/*
			Header Menu Desktop
		*/
		const container = $( '.container' );
		const card_items = $( '.card-inner' );
		const animation_in = container.data( 'animation-in' );
		const animation_out = container.data( 'animation-out' );

		$( '.top-menu' ).on( 'click', 'a', function () {
			/* vars */
			const width = $( window ).width();
			const id = $( this ).attr( 'href' );
			const h = parseFloat( $( id ).offset().top );
			const card_item = $( id );
			const menu_items = $( '.top-menu li' );
			const menu_item = $( this ).closest( 'li' );
			const d_lnk = $( '.lnks .lnk.discover' );

			if ( width >= 1024 ) {
				/* if desktop */
				if (
					! menu_item.hasClass( 'active' ) &
					( width > 1023 ) &
					$( '#home-card' ).length
				) {
					/* close card items */
					menu_items.removeClass( 'active' );
					container
						.find( card_items )
						.removeClass( 'animated ' + animation_in );

					if ( $( container ).hasClass( 'opened' ) ) {
						container
							.find( card_items )
							.addClass( 'animated ' + animation_out );
					}

					/* open card item */
					menu_item.addClass( 'active' );
					container.addClass( 'opened' );
					container
						.find( card_item )
						.removeClass( 'animated ' + animation_out );
					container
						.find( card_item )
						.addClass( 'animated ' + animation_in );

					$( card_items ).addClass( 'hidden' );

					$( card_item ).removeClass( 'hidden' );
					$( card_item ).addClass( 'active' );
				}
			}

			/* if mobile */
			if ( ( width < 1024 ) & $( '#home-card' ).length ) {
				/* scroll to section */
				$( 'body,html' ).animate(
					{
						scrollTop: h - 76,
					},
					800
				);
			}
			return false;
		} );

		$( window ).on( 'resize', function () {
			const width = $( window ).width();
			const height = $( window ).height();

			if ( width < 1024 ) {
				$( '.card-inner' ).removeClass( 'hidden' );
				$( '.card-inner' ).removeClass( 'fadeOutLeft' );
				$( '.card-inner' ).removeClass( 'rotateOutUpLeft' );
				$( '.card-inner' ).removeClass( 'rollOut' );
				$( '.card-inner' ).removeClass( 'jackOutTheBox' );
				$( '.card-inner' ).removeClass( 'fadeOut' );
				$( '.card-inner' ).removeClass( 'fadeOutUp' );
				$( '.card-inner' ).removeClass( 'animated' );

				$( window ).on( 'scroll', function () {
					const scrollPos = $( window ).scrollTop();
					$( '.top-menu ul li a' ).each( function () {
						const currLink = $( this );
						const refElement = $( currLink.attr( 'href' ) );
						if ( refElement.offset().top - 76 <= scrollPos ) {
							$( '.top-menu ul li' ).removeClass( 'active' );
							currLink.closest( 'li' ).addClass( 'active' );
						}
					} );
				} );

				$( '.card-inner .card-wrap' ).slimScroll( { destroy: true } );
				$( '.card-inner .card-wrap' ).attr( 'style', '' );
			} else {
				$( $( '.top-menu li.active a' ).attr( 'href' ) ).addClass(
					'active'
				);
				if ( ! $( '.page' ).hasClass( 'new-skin' ) && width > 1024 ) {
					$( '.card-inner .card-wrap' ).slimScroll( {
						height: '570px',
					} );
				}
			}
		} );

		/*
			Smoothscroll
		*/

		if ( ( width < 1024 ) & $( '#home-card' ).length ) {
			$( window ).on( 'scroll', function () {
				const scrollPos = $( window ).scrollTop();
				$( '.top-menu ul li a' ).each( function () {
					const currLink = $( this );
					const refElement = $( currLink.attr( 'href' ) );
					if ( refElement.offset().top - 76 <= scrollPos ) {
						$( '.top-menu ul li' ).removeClass( 'active' );
						currLink.closest( 'li' ).addClass( 'active' );
					}
				} );
			} );
		}

		/*
			slimScroll
		*/

		if ( ! $( '.page' ).hasClass( 'new-skin' ) && width > 1024 ) {
			$( '.card-inner .card-wrap' ).slimScroll( {
				height: '570px',
			} );
		}

		/*
			Hire Button
		*/

		$( '.lnks' ).on( 'click', '.lnk.discover', function () {
			$( '.top-menu a[href="#contacts-card"]' ).trigger( 'click' );
		} );

		/*
			Initialize Portfolio
		*/
		const $container = $( '.grid-items' );
		$container.imagesLoaded( function () {
			$container.isotope( {
				percentPosition: true,
				itemSelector: '.grid-item',
				masonry: {
					gutter: 0,
				},
			} );
		} );

		/*
			Filter items on button click
		*/
		$( '.filter-button-group' ).on( 'click', '.f_btn', function () {
			const filterValue = $( this ).find( 'input' ).val();
			$container.isotope( { filter: '.' + filterValue } );
			$( '.filter-button-group .f_btn' ).removeClass( 'active' );
			$( this ).addClass( 'active' );
		} );

		/*
			Gallery popup
		*/
		if (
			/\.(?:jpg|jpeg|gif|png)$/i.test(
				$( '.gallery-item:first a' ).attr( 'href' )
			)
		) {
			$( '.gallery-item a' ).magnificPopup( {
				gallery: {
					enabled: true,
				},
				type: 'image',
				closeBtnInside: false,
				mainClass: 'mfp-fade',
			} );
		}

		/*
			Media popup
		*/
		$( '.has-popup-media' ).magnificPopup( {
			type: 'inline',
			overflowY: 'auto',
			closeBtnInside: true,
			mainClass: 'mfp-fade popup-box-inline',
		} );

		/*
			Image popup
		*/
		$( '.has-popup-image' ).magnificPopup( {
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-fade',
			image: {
				verticalFit: true,
			},
		} );

		/*
			Video popup
		*/
		$( '.has-popup-video' ).magnificPopup( {
			disableOn: 700,
			type: 'iframe',
			iframe: {
				patterns: {
					youtube_short: {
						index: 'youtu.be/',
						id: 'youtu.be/',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1',
					},
				},
			},
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,
			mainClass: 'mfp-fade',
			callbacks: {
				markupParse( template, values, item ) {
					template.find( 'iframe' ).attr( 'allow', 'autoplay' );
				},
			},
		} );

		/*
			Music popup
		*/
		$( '.has-popup-music' ).magnificPopup( {
			disableOn: 700,
			type: 'iframe',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,
			mainClass: 'mfp-fade',
		} );

		/*
			Gallery popup
		*/
		$( '.has-popup-gallery' ).on( 'click', function () {
			const gallery = $( this ).attr( 'href' );

			$( gallery )
				.magnificPopup( {
					delegate: 'a',
					type: 'image',
					closeOnContentClick: false,
					mainClass: 'mfp-fade',
					removalDelay: 160,
					fixedContentPos: false,
					gallery: {
						enabled: true,
					},
				} )
				.magnificPopup( 'open' );

			return false;
		} );

		/*
			Validate Contact Form
		*/
		$( '#cform' ).validate( {
			ignore: '.ignore',
			rules: {
				name: {
					required: true,
				},
				message: {
					required: true,
				},
				email: {
					required: true,
					email: true,
				},
				hiddenRecaptcha: {
					required() {
						if ( grecaptcha.getResponse() == '' ) {
							return true;
						}
						return false;
					},
				},
			},
			success: 'valid',
			submitHandler() {
				$.ajax( {
					url: 'mailer/feedback.php',
					type: 'post',
					dataType: 'json',
					data:
						'name=' +
						$( '#cform' ).find( 'input[name="name"]' ).val() +
						'&email=' +
						$( '#cform' ).find( 'input[name="email"]' ).val() +
						'&message=' +
						$( '#cform' ).find( 'textarea[name="message"]' ).val(),
					beforeSend() {},
					complete() {},
					success( data ) {
						$( '#cform' ).fadeOut();
						$( '.alert-success' ).delay( 1000 ).fadeIn();
					},
				} );
			},
		} );

		/*
			Validate Commect Form
		*/
		$( '#comment_form' ).validate( {
			rules: {
				name: {
					required: true,
				},
				message: {
					required: true,
				},
			},
			success: 'valid',
			submitHandler() {},
		} );

		/*
			Google Maps
		*/
		// if($('#map').length) {
		// initMap();
		// }

		/*
			Tesimonials Carousel
		*/
		const revs_slider = $( '.revs-carousel.default-revs .owl-carousel' );

		revs_slider.owlCarousel( {
			margin: 0,
			items: 1,
			autoplay: false,
			autoplayTimeout: 5000,
			autoplayHoverPause: true,
			loop: true,
			rewind: false,
			nav: false,
			dots: true,
		} );

		const rtl_revs_slider = $( '.revs-carousel.rtl-revs .owl-carousel' );

		rtl_revs_slider.owlCarousel( {
			margin: 0,
			items: 1,
			rtl: true,
			autoplay: false,
			autoplayTimeout: 5000,
			autoplayHoverPause: true,
			loop: true,
			rewind: false,
			nav: false,
			dots: true,
		} );

		/*
			New JS
		*/
		$( window ).on( 'resize', function () {
			/*
				Dotted Skills Line On Resize Window
			*/

			const skills_dotted = $( '.skills-list.dotted .progress' );
			const skills_dotted_w = skills_dotted.width();
			if ( skills_dotted.length ) {
				skills_dotted
					.find( '.percentage .da' )
					.css( { width: skills_dotted_w + 1 } );
			}

			/*
				Testimonials Carousel On Resize Window
			*/

			const revs_slider = $( '.revs-carousel .owl-carousel' );
			revs_slider
				.find( '.revs-item' )
				.css( { 'max-width': revs_slider.width() } );
		} );

		/*
			Dotted Skills Line
		*/
		function skills() {
			const skills_dotted = $( '.skills-list.dotted .progress' );
			const skills_dotted_w = skills_dotted.width();
			if ( skills_dotted.length ) {
				skills_dotted.append(
					'<span class="dg"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span>'
				);
				skills_dotted
					.find( '.percentage' )
					.append(
						'<span class="da"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span>'
					);
				skills_dotted
					.find( '.percentage .da' )
					.css( { width: skills_dotted_w } );
			}
		}
		setTimeout( skills, 1000 );

		/*
			Circle Skills Line
		*/

		const skills_circles = $( '.skills-list.circles .progress' );
		if ( skills_circles.length ) {
			skills_circles.append(
				'<div class="slice"><div class="bar"></div><div class="fill"></div></div>'
			);
		}

		/*
			Wrap First Title Word
		*/

		/*$('.content .title').each(function(index) {
			var title = $(this).text().split(' ');
			if(title.length>1){
				var firstWord = title[0];
				var replaceWord = '<span class="first-word">' + firstWord + '</span>';
				var newString = $(this).html().replace(firstWord, replaceWord);
				$(this).html(newString);
			} else {
				$(this).html('<div class="first-letter">'+ $(this).html() + '</div>');
			}
		});*/
	} );

	$( window ).on( 'load', function () {
		const preload = $( '.preloader' );
		preload.find( '.spinner' ).fadeOut( function () {
			preload.fadeOut();
		} );
	} );
} )( jQuery );

/*
	Google Map Options
*/

function initMap() {
	const myLatlng = new google.maps.LatLng( 40.773328, -73.960088 ); // <- Your latitude and longitude
	const styles = [
		{
			featureType: 'water',
			stylers: [
				{
					color: '#d8dee9',
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'landscape',
			stylers: [
				{
					color: '#eeeeee',
				},
			],
		},
	];

	const mapOptions = {
		zoom: 14,
		center: myLatlng,
		mapTypeControl: false,
		disableDefaultUI: true,
		zoomControl: true,
		scrollwheel: false,
		styles,
	};

	const map = new google.maps.Map(
		document.getElementById( 'map' ),
		mapOptions
	);
	const marker = new google.maps.Marker( {
		position: myLatlng,
		map,
		title: 'We are here!',
	} );
}

// ( function( $ ) {
// 	'use strict';
//
// 	// load html
// 	$.get("https://bslthemes.com/ryan/bar/bar.html", function (data) {
// 		$('body').append(data);
// 		console.log(data);
// 	});
//
// } )( jQuery );
